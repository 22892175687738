import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { setDepotMismatch } from '@/redux/action-wrappers/location'
import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { abortCheckoutNewDepot } from '@/redux/checkout/actions'
import { getPromoCode } from '@/redux/promo/selectors'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'
import { track } from '@helpers/analytics'

export default function DepotMismatch() {
  const dispatch = useDispatch()
  const promoCode = useSelector(getPromoCode)

  useEffect(() => {
    track('Modal.DeliveryZoneChange.View')
  }, [])

  const handleCloseRequest = () => {
    track('Modal.DeliveryZoneChange.Click', { value: 'dontChangeAddress' })
    dispatch(setDepotMismatch(false))
    dispatch(setAlertTypeVisibility(alertTypes.DEPOT_MISMATCH, false))
  }

  const handleChangeAddress = () => {
    track('Modal.DeliveryZoneChange.Click', { value: 'changeAddress' })
    dispatch(abortCheckoutNewDepot())
  }

  return (
    <EazeDrawer open onRequestClose={handleCloseRequest}>
      <Modal>
        <Emoji>⚠️</Emoji>
        <Title>{`You're changing delivery zones.`}</Title>
        <Paragraph>
          {`Changing your address will clear your cart${promoCode && ' and promo code'}. Do you want to proceed?`}
        </Paragraph>
        <Button variant={'variant-1'} onClick={handleChangeAddress}>
          {'Yes, change my address'}
        </Button>
        <LinkContainer>
          <LinkButton onClick={handleCloseRequest}>{`Don't change my address`}</LinkButton>
        </LinkContainer>
      </Modal>
    </EazeDrawer>
  )
}
