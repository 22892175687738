import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { resetCurrentAction, setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { getActiveDepotId } from '@/redux/location/selectors'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'
import { track } from '@helpers/analytics'

import { TEST_IDS } from '../test/constants'

export default function CashOnly() {
  const dispatch = useDispatch()
  const activeDepotId = useSelector(getActiveDepotId)

  useEffect(() => {
    if (activeDepotId) track('CashOnly.Modal', { depotId: activeDepotId })
  }, [activeDepotId])

  const handleClick = () => {
    dispatch(resetCurrentAction())
    dispatch(setAlertTypeVisibility(alertTypes.CASH_ONLY, false))
  }

  return (
    <EazeDrawer open onRequestClose={handleClick}>
      <CloseModalIcon onClick={handleClick} src="/static/icons/close.svg" />
      <Modal data-e2eid={TEST_IDS.CASH_ONLY_COMPONENT}>
        <Emoji>💵</Emoji>
        <Title>Cash Only</Title>
        <Paragraph>We are temporarily experiencing payment issues. Apologies for the inconvenience.</Paragraph>
        <Button onClick={handleClick} data-e2eid={TEST_IDS.CASH_ONLY_DISMISS_BUTTON}>
          OK
        </Button>
      </Modal>
    </EazeDrawer>
  )
}
