import { defaultCallback } from '@helpers/callbacks'
import { apiV2 } from 'api'
import errorHandler from 'error-handler'
import { ACH_PAYMENT_METHOD, ACH_ACCOUNTS_SYNCED } from 'helpers/payment'
import { logout } from 'redux/app/actions'
import { getUserId, getToken } from 'redux/auth/selectors'
import { getACH, getDispensaryId } from 'redux/payments/selectors'
import { requestQuote } from 'redux/quote/actions'

import t from './actionTypes'
import { Ach } from './types'

export function fetchAndSwitchPaymentMethod(method) {
  return async (dispatch) => {
    await dispatch(fetchPaymentMethods())
    await dispatch(switchPaymentMethod(method))
  }
}

// fetches providers then fetches each providers paymentMethods
export function fetchPaymentMethods() {
  return async (dispatch, getState) => {
    dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADING })
    const xAuthToken = getToken(getState())
    // bail if the user isn't logged in!
    // this route requires auth
    if (!xAuthToken) {
      dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })
      return
    }

    // get providers
    const dispensaryId = await getDispensaryId(getState())
    if (!dispensaryId) {
      dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })
      return
    }

    const { err, data } = await apiV2.fetchDispensaryProviders({ dispensaryId, enabled: true })
    if (err) {
      if (err.statusCode === 401) {
        return dispatch(logout())
      } else {
        return errorHandler(new Error(err.message))
      }
    }

    const promises = []
    // get saved cards/accounts for each provider
    data.forEach((providerResponse) => {
      const provider = providerResponse.provider
      dispatch(setPaymentProvider(provider))
      if (provider.paymentMethod === ACH_PAYMENT_METHOD) {
        promises.push(dispatch(fetchBankAccounts(provider)))
      }
    })
    return Promise.all(promises).then(() => {dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })})
  }
}

export function fetchBankAccounts(provider) {
  return async (dispatch, getState) => {
    dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADING })
    const userId = getUserId(getState())
    const xAuthToken = getToken(getState())
    // bail if the user isn't logged in!
    // this route requires auth
    if (!xAuthToken) {
      dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })
      return
    }
    let result
    if (window && !window.sessionStorage.getItem(ACH_ACCOUNTS_SYNCED)) {
      const dispensaryId = getDispensaryId(getState())
      result = await apiV2.syncBankAccounts({ providerId: provider.id, userId, dispensaryId })
      if (result.data) window.sessionStorage.setItem(ACH_ACCOUNTS_SYNCED, 'true')
    } else {
      result = await apiV2.getBankAccounts({ providerId: provider.id, userId })
    }
    const { err, data } = result

    dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })
    if (err) {
      if (err.statusCode === 401) {
        return dispatch(logout())
      } else {
        return errorHandler(new Error(err.message))
      }
    }

    // User can only have one account at a time
    if (data && data.length) {
      const ach = {
        ...data[0],
        name: data[0].name || 'Checking Account'
      }

      await dispatch(setACH(ach))
    }
  }
}

export function unsetActivePaymentId() {
  return (dispatch) => {
    dispatch({
      type: t.SWITCH_PAYMENT_METHOD,
      payload: null
    })
  }
}

// this is used to switch {activePaymentId} which is stored
// on the top level of the payments store.
// {activePaymentId: 0} === cash
// {activePaymentId: [uuid]} === credit card
// {activePaymentId: 2} === card on delivery
// {activePaymentId: 3} === debit (pin required) on delivery
// {activePaymentId: 4} === debit cash back on delivery
// {activePaymentId: 5} === ACh
// {activePaymentId: 6} === Debit Online
export function switchPaymentMethod(value) {
  return (dispatch, getState) => {
    const ach = getACH(getState())
    if (value === ACH_PAYMENT_METHOD && !ach) {
      console.error('Attempted to switch to ACH with no bank account added.')
      return
    }
    dispatch({
      type: t.SWITCH_PAYMENT_METHOD,
      payload: value
    })
    dispatch(requestQuote(defaultCallback, true))
  }
}

// Delete ACH
export function deleteACH() {
  return async (dispatch, getState) => {
    const userId = getUserId(getState())
    const ach = getACH(getState())
    dispatch(unsetActivePaymentId())
    const resp = await apiV2.deleteBankAccount({ userId, bankAccountId: ach.id })
    if (resp.err) {
      return errorHandler(new Error(resp.err.message))
    } else {
      dispatch(setACH(null))
    }
  }
}

export function setACH(ach: Ach) {
  return {
    type: t.SET_ACH,
    payload: ach
  }
}

export function setACHAddress(address) {
  return (dispatch, getState) => {
    const ach = getACH(getState())

    dispatch({
      type: t.SET_ACH,
      payload: {
        ...ach,
        address
      }
    })
  }
}

export function setPaymentProvider(provider) {
  return (dispatch) => {
    dispatch({
      type: t.SET_PAYMENT_PROVIDERS,
      payload: provider
    })
  }
}

export function setDriverTip(amount) {
  return (dispatch) => {
    dispatch({
      type: t.SET_DRIVER_TIP,
      payload: amount
    })
  }
}
