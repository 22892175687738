import styled from '@emotion/styled'
import Image from 'next/image'
import Link from 'next/link'
import { useDispatch } from 'react-redux'

import { toggleAddress } from '@/redux/addressModal/actions'
import { resetCurrentAction, setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { clearPotentialAddress } from '@/redux/location/actions'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'
import ROUTES from '@helpers/routes'

import { TEST_IDS } from '../test/constants'

export default function LocationOutOfService() {
  const dispatch = useDispatch()

  const handleClosing = () => {
    dispatch(setAlertTypeVisibility(alertTypes.LOCATION_OUT_OF_SERVICE, false))
    dispatch(clearPotentialAddress())
    dispatch(resetCurrentAction())
  }

  const handleChangingAddress = () => {
    dispatch(setAlertTypeVisibility(alertTypes.LOCATION_OUT_OF_SERVICE, false))
    dispatch(toggleAddress())
    dispatch(resetCurrentAction())
  }

  return (
    <EazeDrawer open onRequestClose={handleClosing}>
      <Modal>
        <CloseModalIcon onClick={handleClosing} src="/static/icons/close.svg" />
        <Content data-e2eid={TEST_IDS.NO_SERVICE_AREA}>
          <ImageContainer>
            <Image src="/static/location.png" width={103} height={103} alt="Location" />
          </ImageContainer>
          <DrawerTitle>Area Not In Service</DrawerTitle>
          <Paragraph>
            Eaze is not currently available in your area. You can{' '}
            <Button variant="variant-10" onClick={handleChangingAddress}>
              try a different address
            </Button>{' '}
            or <Link href={ROUTES.WEED_DELIVERY}>view our current service area</Link>
          </Paragraph>
          <Link href={ROUTES.WEED_DELIVERY}>
            <Button data-e2eid={TEST_IDS.VIEW_AREA}>View Service Area</Button>
          </Link>
          <LinkContainer>
            <Button variant="variant-10" data-e2eid={TEST_IDS.CHANGE_ADDRESS} onClick={handleChangingAddress}>
              Change My Address
            </Button>
          </LinkContainer>
        </Content>
      </Modal>
    </EazeDrawer>
  )
}

const Content = styled.div`
  text-align: center;
`

const ImageContainer = styled.div`
  margin-left: 0.33rem;
`

const DrawerTitle = styled(Title)`
  margin-bottom: 2rem;
`
