/**
 * Non existent account modal component
 * Informs user the email or phone number they tried is not tied to an account
 * and gives the option to sign up or try a different identifier
 */

import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { LinkButton, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'
import ROUTES from '@helpers/routes'

export default function NonExistentAccount() {
  const dispatch = useDispatch()
  const router = useRouter()

  const handleRequestUseDifferentIdentifier = () => {
    dispatch(setAlertTypeVisibility(alertTypes.NON_EXISTENT_EMAIL, false))
  }

  const handleRequestNewAccount = () => {
    dispatch(setAlertTypeVisibility(alertTypes.NON_EXISTENT_EMAIL, false))
    router.push(ROUTES.SIGNUP)
  }

  return (
    <EazeDrawer open allowClose={false} onRequestClose={handleRequestUseDifferentIdentifier}>
      <Modal>
        <Title>Oops!</Title>
        <Paragraph>{`That email address doesn't match our records. Try again or sign up for a new account.`}</Paragraph>
        <Button onClick={handleRequestNewAccount}>Create a new account</Button>
        <LinkContainer>
          <LinkButton onClick={handleRequestUseDifferentIdentifier}>Try a different email</LinkButton>
        </LinkContainer>
      </Modal>
    </EazeDrawer>
  )
}
