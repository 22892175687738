/**
 * Global variables
 *
 * If adding to this, make sure to restart webpack when in development
 * Otherwise values will not populate in css.
 *
 * Naming convention - [css attribute][semantic component being modified][optional modifier]
 */


const white = '#FFFFFF'
const normalFontSize = '1.6rem'
const defaultBorderRadius = '0.4rem'
const productPageMobileWidth = '42rem'


module.exports = {
  defaultBorderRadius: defaultBorderRadius,
  white,
  fontSizeParagraph: normalFontSize,
  widthProductPageMobile: productPageMobileWidth,
}
