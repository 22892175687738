/**
 * Modal that shows up on /menu after a successful password reset / change.
 */

import { useDispatch } from 'react-redux'

import { setChangePassword } from '@/redux/profile/actions'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function ChangedPassword() {
  const dispatch = useDispatch()

  const handleCloseRequest = () => {
    dispatch(setChangePassword(false))
  }

  return (
    <EazeDrawer open onRequestClose={handleCloseRequest}>
      <CloseModalIcon onClick={handleCloseRequest} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>🔑</Emoji>
        <Title>Success!</Title>
        <Paragraph>Your password has successfully been changed.</Paragraph>
        <Button onClick={handleCloseRequest}>Start shopping</Button>
      </Modal>
    </EazeDrawer>
  )
}
