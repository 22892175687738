import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { getDepotMinimumOrder } from '@/redux/location/selectors'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'
import ROUTES from '@helpers/routes'

export default function UnderMinimum() {
  const dispatch = useDispatch()
  const router = useRouter()
  const cartMinimum = useSelector(getDepotMinimumOrder)

  const onRequestClose = () => {
    dispatch(setAlertTypeVisibility(alertTypes.UNDER_MINIMUM, false))
  }

  const onClick = () => {
    router.push(ROUTES.MENU)
  }

  return (
    <EazeDrawer open onRequestClose={onRequestClose}>
      <CloseModalIcon onClick={onRequestClose} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>🙌</Emoji>
        <Title>Almost there!</Title>
        <Paragraph>
          Eaze has a ${cartMinimum} minimum to place an order. Add items to your cart to meet the minimum.
        </Paragraph>
        <Button onClick={onClick}>Browse full Eaze Menu</Button>
        <LinkContainer>
          <LinkButton onClick={onRequestClose}>View Cart</LinkButton>
        </LinkContainer>
      </Modal>
    </EazeDrawer>
  )
}
