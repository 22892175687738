/**
 * Existing account modal component
 * Informs user an account already exists for the identifier submitted
 */

import { useEffect } from 'react'

import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { logout } from '@/redux/app/actions'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'
import { track } from '@helpers/analytics'
import ROUTES from '@helpers/routes'

import { TEST_IDS } from '../test/constants'

export default function ExistingAccountIdentifier({ identifierType }: { identifierType: 'EMAIL' | 'PHONE' }) {
  const dispatch = useDispatch()
  const router = useRouter()

  const hasExistingEmail = identifierType === 'EMAIL'
  const alertKey = hasExistingEmail ? alertTypes.EXISTING_EMAIL_ADDRESS : alertTypes.EXISTING_PHONE_NUMBER

  const handleRedirectToLogin = () => {
    if (hasExistingEmail) {
      track('Signup.DuplicateEmail.Login')
    } else {
      track('Signup.DuplicatePhone.Login')
    }
    dispatch(setAlertTypeVisibility(alertKey, false))
    // User in verification flow will be logged into the account they're creating and should be logged out
    dispatch(logout(ROUTES.LOGIN))
  }

  const handleRedirectToResetPassword = () => {
    if (hasExistingEmail) {
      track('Signup.DuplicateEmail.ResetPassword')
    } else {
      track('Signup.DuplicatePhone.ResetPassword')
    }
    dispatch(setAlertTypeVisibility(alertKey, false))
    router.push(ROUTES.RESET_PASSWORD)
  }

  useEffect(() => {
    if (hasExistingEmail) {
      track('Signup.DuplicateEmail.View')
    } else {
      track('Signup.DuplicatePhone.View')
    }
  }, [])

  const handleCloseRequest = () => {
    dispatch(setAlertTypeVisibility(alertKey, false))
  }

  const identifierTypeDisplay = hasExistingEmail ? 'email address' : 'phone number'

  return (
    <EazeDrawer open onRequestClose={handleCloseRequest}>
      <Modal data-e2eid={TEST_IDS.DUPLICATE_ACCOUNT_MODAL}>
        <Emoji>👋</Emoji>
        <Title>Good to see you again!</Title>
        <Paragraph>
          Your {identifierTypeDisplay} has already been used to create an account. Would you like to log in or reset
          your password?
        </Paragraph>

        <Button onClick={handleRedirectToLogin}>Log in</Button>
        <LinkContainer>
          <LinkButton onClick={handleRedirectToResetPassword}>Reset my password</LinkButton>
        </LinkContainer>
      </Modal>
    </EazeDrawer>
  )
}
