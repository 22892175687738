import Link from 'next/link'
import { useDispatch } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { acceptCollectiveAgreement } from '@/redux/profile/actions'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function CollectiveAgreement() {
  const dispatch = useDispatch()

  const handleCloseRequest = () => {
    dispatch(setAlertTypeVisibility(alertTypes.COLLECTIVE_AGREEMENT_NOT_SIGNED, false))
    dispatch(acceptCollectiveAgreement())
  }

  return (
    <EazeDrawer open allowClose={false} onRequestClose={handleCloseRequest}>
      <Modal>
        <Emoji>✍️</Emoji>
        <Title>{`We've updated our agreements!`}</Title>
        <Paragraph style={{ marginBottom: '1rem' }}>
          In order to continue using Eaze, please review our updated terms.
        </Paragraph>
        <Paragraph>
          {`By clicking "I Agree," I accept Eaze's `}
          <Link href="/terms-of-service" rel="noopener noreferrer" target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link href="/privacy-policy" rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </Link>
          .
        </Paragraph>
        <Button onClick={handleCloseRequest}>I agree</Button>
      </Modal>
    </EazeDrawer>
  )
}
