import { useDispatch } from 'react-redux'

import { toggleRemovedPromo } from '@/redux/promo/actions'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function RemovedPromo() {
  const dispatch = useDispatch()

  const removePromo = () => {
    dispatch(toggleRemovedPromo())
  }

  return (
    <EazeDrawer open onRequestClose={removePromo}>
      <CloseModalIcon onClick={removePromo} src="/static/icons/close.svg" alt="Close Popup" />
      <Modal>
        <Emoji>😢</Emoji>
        <Title>{`Your promo won't work anymore`}</Title>
        <Paragraph>
          {`Looks like your cart doesn't meet the promo criteria. We had to remove the promo from your cart.`}
        </Paragraph>
        <Button onClick={removePromo}>OK</Button>
      </Modal>
    </EazeDrawer>
  )
}
