import { useDispatch } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { setExpiredCart } from '@/redux/cart/actions'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function CartExpired() {
  const dispatch = useDispatch()

  const requestClose = () => {
    dispatch(setExpiredCart(false))
    dispatch(setAlertTypeVisibility(alertTypes.CART_EXPIRED, false))
  }

  return (
    <EazeDrawer open onRequestClose={requestClose}>
      <CloseModalIcon onClick={requestClose} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>💤</Emoji>
        <Title>Cart Expired</Title>
        <Paragraph>Uh oh! Looks like your cart got tired of waiting so we emptied it for you.</Paragraph>
        <Button onClick={requestClose}>Keep Shopping</Button>
      </Modal>
    </EazeDrawer>
  )
}
