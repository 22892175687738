import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { alertTypes } from '@/redux/alert/config/types'
import { getVisibleModalKey } from '@/redux/alert/selectors'
import AgeGate from '@/stories/modals/age-gate'
import Announcement from '@/stories/modals/announcement'
import AppOutdated from '@/stories/modals/app-outdated'
import CartExpired from '@/stories/modals/cart-expired'
import CartSizeMismatch from '@/stories/modals/cart-size-mismatch'
import CashOnlyModal from '@/stories/modals/cash-only'
import ChangedPassword from '@/stories/modals/changed-password'
import CollectiveAgreement from '@/stories/modals/collective-agreement'
import DepotMismatch from '@/stories/modals/depot-mismatch'
import DuplicateAccountDetected from '@/stories/modals/duplicate-account-detected'
import ExistingAccountIdentifier from '@/stories/modals/existing-account-identifier'
import LocationOutOfService from '@/stories/modals/location-out-of-service'
import NonExistentAccountIdentifier from '@/stories/modals/non-existent-account-identifier'
import PhoneVerificationIncomplete from '@/stories/modals/phone-verification-incomplete'
import RemovedPromo from '@/stories/modals/removed-promo'
import ShowIDs from '@/stories/modals/show-ids'
import Modal2FA from '@/stories/modals/two-factor-auth'
import UnderAgeMinimum from '@/stories/modals/under-age-minimum'
import UnderMinimum from '@/stories/modals/under-minimum'
import UploadError from '@/stories/modals/upload-error'
import VerificationDeclined from '@/stories/modals/verification-declined'

export default function Modal() {
  const router = useRouter()
  const alertKey = useSelector((state) => getVisibleModalKey(state, router.pathname, router.query))

  switch (alertKey) {
    case alertTypes.ANNOUNCEMENT:
      return <Announcement />

    case alertTypes.AGE_GATE:
      return <AgeGate />

    case alertTypes.CART_EXPIRED:
      return <CartExpired />

    case alertTypes.CART_SIZE_MISMATCH:
      return <CartSizeMismatch />

    case alertTypes.CHANGED_PASSWORD:
      return <ChangedPassword />

    case alertTypes.COLLECTIVE_AGREEMENT_NOT_SIGNED:
      return <CollectiveAgreement />

    case alertTypes.CASH_ONLY:
      return <CashOnlyModal />

    case alertTypes.DEPOT_MISMATCH:
      return <DepotMismatch />

    case alertTypes.EXISTING_EMAIL_ADDRESS:
      return <ExistingAccountIdentifier identifierType={'EMAIL'} />

    case alertTypes.EXISTING_PHONE_NUMBER:
      return <ExistingAccountIdentifier identifierType={'PHONE'} />

    case alertTypes.IMAGE_UPLOAD_ERROR:
      return <UploadError />

    case alertTypes.NEED_TO_SHOW_ID:
      return <ShowIDs />

    case alertTypes.NEED_2FA_REAUTH:
      return <Modal2FA />

    case alertTypes.HAS_LINKED_ACCOUNTS:
      return <DuplicateAccountDetected />

    case alertTypes.NON_EXISTENT_EMAIL:
      return <NonExistentAccountIdentifier />

    case alertTypes.LOCATION_OUT_OF_SERVICE:
      return <LocationOutOfService />

    case alertTypes.PHONE_VERIFICATION_INCOMPLETE:
      return <PhoneVerificationIncomplete />

    case alertTypes.REMOVED_PROMO:
      return <RemovedPromo />

    case alertTypes.UNDER_AGE_MINIMUM:
      return <UnderAgeMinimum />

    case alertTypes.UNDER_MINIMUM:
      return <UnderMinimum />

    case alertTypes.VERIFICATION_DECLINED:
      return <VerificationDeclined />

    case alertTypes.VERSION_MISMATCH:
      return <AppOutdated />

    default:
      return null
  }
}
