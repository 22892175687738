import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { cartSizeMismatch } from '@/redux/cart/actions'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function CartSizeMismatch() {
  const dispatch = useDispatch()
  const router = useRouter()

  const handleCloseRequest = () => {
    dispatch(cartSizeMismatch(false))
    dispatch(setAlertTypeVisibility(alertTypes.CART_SIZE_MISMATCH, false))
    // refresh page to make sure that unavailable items are cleared from menu
    router.reload()
  }

  return (
    <EazeDrawer open onRequestClose={handleCloseRequest}>
      <Modal>
        <Emoji>😓</Emoji>
        <Title>Unavailable items removed from cart</Title>
        <Paragraph>
          Sorry! We had to remove one or more items from your cart because they are no longer available.
        </Paragraph>
        <Button onClick={handleCloseRequest}>Keep Shopping</Button>
      </Modal>
    </EazeDrawer>
  )
}
