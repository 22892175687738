import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { getDeclinedComment, getDeclinedReasons } from '@/redux/profile/selectors'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Comment, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function VerificationDeclined() {
  const dispatch = useDispatch()
  const declinedComment = useSelector(getDeclinedComment)
  const declinedReasons = useSelector(getDeclinedReasons)

  const onClick = () => {
    dispatch(setAlertTypeVisibility(alertTypes.VERIFICATION_DECLINED, false))
  }

  const reasons = declinedReasons?.map((reason, i) => {
    return <Reasons key={i}>{reason}</Reasons>
  })

  const comments =
    declinedComment && declinedComment.length > 0 ? (
      <Comment>
        <Title>Comments:</Title>
        <Paragraph>{declinedComment}</Paragraph>
      </Comment>
    ) : null

  return (
    <EazeDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>😢</Emoji>
        <Title>We had some issues verifying your account</Title>
        <Paragraph>{reasons}</Paragraph>
        {comments}
        <Button onClick={onClick}>Fix it</Button>
      </Modal>
    </EazeDrawer>
  )
}

const Reasons = styled.div`
  margin-bottom: 1rem;
`
