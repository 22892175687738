import { FormEvent, Fragment, useEffect, useState } from 'react'

import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react'
import debounce from 'debounce'
import Image from 'next/image'
import { connect, useDispatch, useSelector } from 'react-redux'

import { resetCurrentAction } from '@/redux/alert/actions'
import { dismiss2faModal, resetProfileChange } from '@/redux/auth/actions'
import { getAuth, getUserId, getUserPhoneNumber } from '@/redux/auth/selectors'
import { openPersonaModal } from '@/redux/modal/actions'
import { fetchUserProfile } from '@/redux/profile/actions'
import { Button } from '@/stories/buttons/button'
import { CodeInput } from '@/stories/inputs/code-input'
import { Subtext, Title } from '@/stories/typography'
import { lightTheme } from '@/theme'
import { parser } from '@components/form-inputs/constants'
import { A11yHiddenLabel } from '@components/form-inputs/label'
import { track } from '@helpers/analytics'
import { apiV2 } from '@helpers/api'
import { CODE_INPUT_CONFIRMATION_ERROR, SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '@helpers/constants'
import errorHandler from '@helpers/error-handler'
import AlertDrawer from '@stories/modals/alert/alert-modal'

import { InferProps, func, shape, string } from 'prop-types'

/* OTP TYPES
 * 'sms' will send the user an OTP via text message
 * 'email' will send the user an OTP via email
 * 'phone' will send the user an OTP via phone call
 */
async function sendCode(verificationType: 'sms' | 'email' | 'phone') {
  const { err } = await apiV2.sendUserOTP({ verificationType })
  if (err) {
    errorHandler(new Error(`Error sending OTP to user: ${err.message}`))
  }
}

export default function Modal2FA() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [isLoading, setLoading] = useState(false)
  const [userSentCode, setUserSentCode] = useState(false)
  const userId = useSelector(getUserId)
  const userPhoneNumber = useSelector(getUserPhoneNumber)
  // FUTURE TODO: use 'sms' for email changes and 'email' for phone changes
  // const authState = useSelector(getAuth)
  const typeOf2FA = 'sms' // authState.attemptProfileChange === 'phone' ? 'email' : 'sms'

  async function handleRequest() {
    setLoading(true)
    track('Verify2FA.TextMe.Click')
    await sendCode(typeOf2FA)
    setUserSentCode(true)
    setLoading(false)
  }

  function closeModal() {
    dispatch(dismiss2faModal())
  }

  function RequestOTP() {
    return (
      <ContentContainer>
        <Image unoptimized src="/static/icons/Lock2.svg" alt="lock" width={30} height={24} />
        <Title
          componentStyle={{
            marginTop: theme.spacing.smaller,
            marginBottom: theme.spacing.smaller
          }}>{`Let's make sure it's you`}</Title>
        <Subtext
          componentStyle={{
            fontFamily: theme.typography.font.simMono,
            marginTop: theme.spacing.smaller
          }}>{`To access and edit your account settings, we'll need to verify it's you. We will send a 4 digit code to ${
          typeOf2FA !== 'sms' ? 'your email' : `the number ending in ${userPhoneNumber.slice(-4)}`
        }, and use it to verify your identity.`}</Subtext>
        <Button
          variant="variant-1"
          onClick={debounce(handleRequest, 1000, true)}
          loading={isLoading}
          margin={`${theme.spacing.larger}px`}>
          {typeOf2FA !== 'sms' ? `Email me` : `Text me`}
        </Button>
      </ContentContainer>
    )
  }

  return userId ? (
    <SplitClient splitKey={userId}>
      <SplitTreatments names={[SPLIT_EXPERIMENTS.PROFILE_PAGE_2FA]}>
        {({ isReady, treatments }) => {
          const treatmentIsOn =
            treatments[SPLIT_EXPERIMENTS.PROFILE_PAGE_2FA].treatment === SPLIT_TREATMENTS.PROFILE_PAGE_2FA.ON
          return isReady && treatmentIsOn ? (
            <AlertDrawer open showCloseButton onRequestClose={closeModal} modalElementClass={drawerContainerCss}>
              {userSentCode ? <ConfirmOTP /> : <RequestOTP />}
            </AlertDrawer>
          ) : null
        }}
      </SplitTreatments>
    </SplitClient>
  ) : null
}

/*
 * This is a class component instead of functional component because of the tracking event in componentDidMount
 *
 * This event should only fire once, when this screen is viewed
 *
 * If this is a functional component, adding a View tracking event causes
 * the event to be called *every* time the component renders and not just the first time
 *
 * If there is a confirmation error, this causes the component to re-render and triggers the View event a second time
 *
 * To prevent this, I'm using componentDidMount/PureComponent to limit event to first render only
 */
const mapStateToProps = (state) => ({
  authState: getAuth(state),
  userPhoneNumber: getUserPhoneNumber(state)
  // userEmail: getUserEmail(state)
})
const mapDispatchToProps = (dispatch) => ({
  fetchUserProfile: () => dispatch(fetchUserProfile()),
  openPersonaModal: () => dispatch(openPersonaModal()),
  resetCurrentAction: () => dispatch(resetCurrentAction()),
  resetProfileChange: () => dispatch(resetProfileChange())
})

type OTPConfirmationProps = InferProps<typeof OTPConfirmation.propTypes>
export function OTPConfirmation({
  authState,
  fetchUserProfile,
  openPersonaModal,
  resetCurrentAction,
  resetProfileChange,
  userPhoneNumber
}: OTPConfirmationProps) {
  const [code, setCode] = useState<string>('')
  const [confirmationError, setConfirmationError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    track('Verify2FA.VerificationCode.View')
  }, []) // componentDidMount

  const resendCode = async () => {
    track('Verify2FA.ResendCode')
    setCode('')
    setConfirmationError('')
    // FUTURE TODO: use 'sms' for email changes and 'email' for phone changes
    // const verificationType = this.props.authState.attemptProfileChange === 'phone' ? 'email' : 'sms'
    await sendCode('sms')
  }

  function handleCodeChange(code: string) {
    setCode(code)
  }

  const handleConfirmation = async (e: FormEvent) => {
    e.preventDefault()

    setIsLoading(true)

    track('Verify2FA.VerificationCode.Submit')

    const profileChange = authState.attemptProfileChange

    let profileChangeError = null

    if (profileChange === 'phone') {
      const { err } = await apiV2.verifyPhoneWith2FA({
        to: parser(authState.phoneChangeAttempt),
        verificationType: 'sms',
        verificationCode: code
      })
      if (err) profileChangeError = err
    } else if (profileChange === 'email') {
      const { err } = await apiV2.verifyEmailWith2FA({
        to: authState.emailChangeAttempt,
        verificationType: 'sms',
        verificationCode: code
      })
      if (err) profileChangeError = err
    } else if (profileChange === 'id') {
      const { err } = await apiV2.verifyOTP({
        verificationType: 'sms',
        verificationCode: code
      })
      if (err) profileChangeError = err
    }

    if (profileChangeError) {
      track('Verify2FA.VerificationCode.ConfirmationError')
      let confirmationError: string = profileChangeError.message
      if (profileChangeError.statusCode === 403) {
        confirmationError = CODE_INPUT_CONFIRMATION_ERROR
      } else if (!confirmationError.match('already registered')) {
        // if the error is something other than wrong code (403 status code) or dupe email/phone, then send error to sentry
        errorHandler(new Error(`Error confirming OTP when changing ${profileChange}: ${confirmationError}`))
      }
      setConfirmationError(confirmationError)
      setIsLoading(false)
    } else {
      track('Verify2FA.VerificationCode.Success')
      if (profileChange !== 'id') {
        fetchUserProfile()
      }
      resetCurrentAction()
      resetProfileChange()
      setIsLoading(false)
      if (profileChange === 'id') {
        openPersonaModal()
      }
    }
  }

  return (
    <ContentContainer>
      <Image src="/static/icons/Lock2.svg" alt="lock" width={30} height={24} />
      <Title componentStyle={{ marginBottom: lightTheme.spacing.large, marginTop: lightTheme.spacing.medium }}>
        Enter Code
      </Title>
      <form onSubmit={debounce(handleConfirmation, 1000, true)}>
        <A11yHiddenLabel htmlFor="code">OTP Code</A11yHiddenLabel>
        <CodeInput
          onChange={handleCodeChange}
          hasWrongCode={!!confirmationError.length}
          errorMessage={confirmationError}
        />
        <Button type="submit" disabled={code.length < 1} loading={isLoading}>
          Verify me
        </Button>
      </form>
      <Subtext componentStyle={{ fontFamily: lightTheme.typography.font.simMono, marginTop: lightTheme.spacing.large }}>
        {/* FUTURE TODO: show phone number for email changes and email for phone changes */}
        {/* {`We've sent a code to ${authState.phoneChangeAttempt ? userEmail : `******-${userPhoneNumber.slice(-4)}`}.`} */}
        {`We've sent a code to ******-${userPhoneNumber.slice(-4)}.`}
        <br />
        {`If you don't receive a code within 5 min, please click below, we'll send another.`}
      </Subtext>
      <ButtonLink type="button" onClick={debounce(resendCode, 1000, true)}>
        Resend Code
      </ButtonLink>
      {confirmationError && (
        <Fragment>
          <SupportMessage>Trouble receiving your code?</SupportMessage>
          <SupportLink href="https://help.eaze.com/hc/en-us" target="_blank" rel="noopener noreferrer">
            Support Center
          </SupportLink>
        </Fragment>
      )}
    </ContentContainer>
  )
}

OTPConfirmation.propTypes = {
  authState: shape({
    attemptProfileChange: string,
    emailChangeAttempt: string,
    phoneChangeAttempt: string
  }),
  fetchUserProfile: func,
  openPersonaModal: func,
  resetCurrentAction: func,
  resetProfileChange: func,
  userPhoneNumber: string
}

const ConfirmOTP = connect(mapStateToProps, mapDispatchToProps)(OTPConfirmation)

const drawerContainerCss = css`
  height: calc(100%);
  position: absolute;
  background: white;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  outline: none;
  top: 0;
  left: 0;
  text-align: center;
  padding: 2rem 3.75rem 0;
  font-family: 'Suisse Intl';
  padding-top: 10rem;

  @media (min-width: 768px) {
    overflow-y: auto;
    left: initial;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const ContentContainer = styled.div`
  max-width: 480px;
`

const SupportMessage = styled.p`
  font-family: ${({ theme }) => theme.typography.font.simMono};
  margin-top: 10rem;
`

const SupportLink = styled.a`
  text-decoration: underline !important;
`

// color: ${(props) => props.theme.colors.eazeBlue};
const ButtonLink = styled.button`
  background: none;
  border: 0;
  margin: 36px auto;
  padding: 0;
  color: ${({ theme }) => theme.colors.purpleHaze};
  text-decoration: underline;
  cursor: pointer;
`
